
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Auto } from '@/entities/public'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'

@Component({
  components: { StockPhotos },
})
  export default class AutoResume extends Vue {
  @Prop({ type: Auto, default: '' }) auto!: Auto
  @Prop({ type: String, default: '' }) mileage!: string
  @Prop({ type: String, default: '' }) fuel!: string
  @Prop({ type: String, default: '' }) transmission!: string
  @Prop({ type: String, default: '' }) traction!: string
  @Prop({ type: String, default: null }) owners!: string

  // Methods

  // Getters

  // Watchers
  }
